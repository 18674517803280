import React, { useState, useEffect } from "react";

const QuesMark = () => {
  const [dashOffset1, setDashOffset1] = useState(3320.56);
  const [dashOffset2, setDashOffset2] = useState(382.935);

  useEffect(() => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animateDashOffsets = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const progress = elapsed / animationDuration;
        const intermediateDashOffset1 = 3320.56 - progress * 3320.56;
        const intermediateDashOffset2 = 382.935 - progress * 382.935;
        setDashOffset1(intermediateDashOffset1);
        setDashOffset2(intermediateDashOffset2);
        requestAnimationFrame(animateDashOffsets);
      } else {
        setDashOffset1(0);
        setDashOffset2(0);
      }
    };
    animateDashOffsets();
  }, []);
  return (
    <div className="bg-slate-400 h-full flex items-center justify-center overflow-hidden p-10">
      <svg
        version="1.1"
        id="object-mark-qs"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 2510 478"
        className="inline"
        xmlSpace="preserve"
      >
        <g>
          <path
            stroke="#FFF"
            strokeWidth="12"
            fill="none"
            d="M25,432.8h2076.2c5.5,0,10-4.6,10-10.1v-63.1v-29.1c0-30.4,3.4-52.5,10.3-67.4c7.1-15.4,20.2-27.4,38.7-35.7 l23.5-10.5c15.1-6.7,22.4-15.6,22.4-27c0-6.5-2.2-11.8-6.6-16.3c-4.4-4.5-9.7-6.6-16.1-6.6c-11.5,0-26.8,4.2-26.8,40.4l0,0 c0,3.9-3.1,7.1-7,7.1h-119.1c-3.9,0-7-3.2-7-7.1l0,0c0-43.7,10.2-79.9,30.3-107.8c14.9-20.6,34.4-37.2,58.1-49.2 c23.7-12.1,48.5-18.2,73.9-18.2c44.9,0,83.3,15.3,114.1,45.4c30.8,30.2,46.5,68.1,46.5,112.7c0,55.5-22.7,95-67.4,117.6 c-17,8.6-21,15.8-22,17.6c-1.9,3.5-1.4,27.1-1.4,27.1"
            style={{
              strokeDasharray: "3320.56 3320.56",
              strokeDashoffset: dashOffset1,
            }}
          ></path>
          <path
            stroke="#FFF"
            strokeWidth="12"
            fill="none"
            d="M2483.7,446.9h-218.3c-13.3,0-24-10.9-24-24.3v-39.5c0-5.6-4.5-10.1-10-10.1H2160"
            style={{
              strokeDasharray: "382.935 382.935",
              strokeDashoffset: dashOffset2,
            }}
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default QuesMark;
