import React from "react";
import { IoClose } from "react-icons/io5";

const MobileMenu = ({ logo, toggleMenu, menuItems }) => (
  <div className="xl:hidden fixed top-0 left-0 h-full bg-gray-800 w-56 flex flex-col z-50 p-3">
    <div className="flex items-center justify-between">
      <div>
        <img src={logo} alt="Logo" className="h-10" />
      </div>
      <div>
        <IoClose className="text-4xl text-white" onClick={toggleMenu} />
      </div>
    </div>
    <div className="border-b my-3"></div>
    <ul className="w-full space-y-3">
      {menuItems.map((item, index) => (
        <li key={index}>
          <a href={item.href} className="text-white">
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default MobileMenu;
