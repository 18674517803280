import React from "react";

const MenuItem = ({ item }) => (
  <li className={`relative group ${item.submenu ? "group" : ""}`}>
    <a href={item.href} className="text-white">
      {item.text}
    </a>
    {item.submenu && (
      <ul className="absolute hidden bg-[#3d5a80] text-white py-2 px-4 rounded group-hover:block min-w-56 right-0 z-10">
        {item.submenu.map((subItem, index) => (
          <li key={index} className="cursor-pointer mt-2">
            {subItem}
          </li>
        ))}
      </ul>
    )}
  </li>
);

export default MenuItem;
