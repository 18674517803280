import React from "react";
import FooterColumn from "./FooterColumn";
import imageLinks from "../data/imageLinks.json";

const Footer = () => {
  const logo = imageLinks.logo;
  return (
    <footer className="bg-[#3d5a80] text-white py-10">
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="md:flex md:justify-center lg:block">
          <img
            src={logo}
            alt="Logo"
            className="max-w-48 h-auto object-contain mt-[-20px]"
          />
        </div>
        <div className="lg:w-3/4 mb-4 lg:mb-0 md:flex mx-auto">
          <FooterColumn
            title="OTHER ALZIMIND PROJECTS"
            links={[
              { text: "CDC ROAD MAP RESOURCES ON ALZHEIMERS DISEASE", href: "#a" },
            ]}
          />
          <FooterColumn
            title="QUICK LINKS"
            links={[
              { text: "CDC’S HEALTHY BRAIN INITIATIVE", href: "#b" },
              { text: "BEST PRACTICE CAREGIVING", href: "#a" },
              { text: "KAER – ALZHEIMERS IN PRIMARY CARE", href: "#a" },
              { text: "USA2 – PLACE AND BRAIN HEALTH EQUITY", href: "#a" },
              { text: "VIDEO TUTORIALS FOR PROVIDERS", href: "#a" },
              { text: "COGNITION IN PRIMARY CARE", href: "#a" },
            ]}
          />
          <FooterColumn
            title="SITE LINKS"
            links={[
              { text: "ABOUT US", href: "#b" },
              { text: "ALZHEIMERS DISEASE DETECTION TOOLKITS", href: "#a" },
              { text: "EARLY DETECTION OF ALZHEIMERS DISEASE", href: "#a" },
              { text: "TECHNICAL ASSISTANCE FORM", href: "#a" },
              { text: "RESOURCES", href: "#a" },
              { text: "FAQS", href: "#a" },
              { text: "CONNECT", href: "#a" },
            ]}
          />
        </div>
      </div>
      <div className="text-center mt-5">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} ALZIMIND. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
