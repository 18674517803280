import React, { useState } from "react";
import imageLinks from "../../data/imageLinks.json";
import { IoMenu, IoClose } from "react-icons/io5";
import MobileMenu from "./MobileMenu";
import MenuItem from "./MenuItem";

const Header = () => {
  const logo = imageLinks.logo;
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuItems = [
    { href: "#a", text: "ABOUT US" },
    {
      href: "#b",
      text: "EARLY DETECTION OF ALZHEIMERS",
      submenu: [
        "WHAT IS ALZHEIMERS DISEASE?",
        "WHAT IS EARLY DETECTION OF ALZHEIMERS?",
      ],
    },
    { href: "#c", text: "TECHNICAL ASSISTANCE" },
    {
      href: "#d",
      text: "RESOURCES",
      submenu: [
        "COLLABORATORS",
        "ALZHEIMERS DETECTION TOOLKITS",
        "FEATURED LINKS",
      ],
    },
    { href: "#e", text: "FAQS" },
    { href: "#f", text: "CONNECT" },
    { href: "#g", text: "EVENTS", submenu: ["WEBINARS", "SYMPOSIUM 2022"] },
  ];
  return (
    <header className="bg-[#3d5a80] py-2 px-5">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-20 mr-6" />
          <p className="text-white font-bold text-2xl">A L Z I M I N D</p>
        </div>
        <div className="xl:hidden">
          {showMenu ? (
            <IoClose className="text-5xl text-white" onClick={toggleMenu} />
          ) : (
            <IoMenu className="text-5xl text-white" onClick={toggleMenu} />
          )}
        </div>
        <ul className={`hidden xl:flex lg:space-x-5 font-bold text-sm`}>
          {menuItems.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </ul>
        {showMenu && (
          <MobileMenu
            logo={logo}
            toggleMenu={toggleMenu}
            menuItems={menuItems}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
