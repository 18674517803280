import Header from "./components/Header/Header";
import SlideShow from "./components/SlideShow";
import imageLinks from "./data/imageLinks.json";
import WhoWeAre from "./components/WhoWeAre";
import OurMission from "./components/OurMission";
import WeFacilitate from "./components/WeFacilitate";
import GetInTouch from "./components/GetInTouch";
import Footer from "./components/Footer";
import QuesMark from "./animation/QuesMark";

function App() {
  return (
    <>
      <Header />
      <SlideShow
        imageLinks={imageLinks}
        text1="INCREASE EARLY DETECTION AND AWARENESS OF ALZHEIMERS DISEASE"
        text2="AS A MANAGEABLE CHRONIC CONDITION"
      />
      <WhoWeAre
        Headings="WHO WE ARE"
        Description1="Alzimind is a well established leader in bringing technology focussed precision medicine to treat various conditions, making it a Health Center of Excellence (HCOE) dedicated to improving early detection of dementia (EDD). Our aim is implementation of evidence-based and evidence-informed precision medicine that increase capacity for early detection of Alzheimers Disease."
        Description2="We have a team of well informed and experienced professionals who are dedicated to the cause of early detection of dementia. Our team is committed to the cause of early detection of dementia and is working towards the goal of making early detection of dementia a routine practice nationwide."
        aboutImage={imageLinks.aboutImage}
      />
      <OurMission
        Headings="OUR MISSION"
        Description1="At our company, we are committed to making early detection of Alzheimer's disease a routine practice nationwide. By identifying Alzheimer's in its early stages, we can prevent crises and improve the health and wellbeing of older adults and their families"
        Description2="Our aim is to reshape the understanding of Alzheimer's as a manageable chronic condition, achievable through public health, clinical, and community initiatives. We focus on empowering individuals and stakeholders to develop systems that not only improve early detection but also provide access to comprehensive and effective care"
        Description3="In our pursuit of these goals, we synthesize evidence-based strategies to enhance Alzheimer's detection. We are dedicated to identifying the necessary components for crafting collaborative solutions and promoting change within organizations and communities across the country."
        Description4="As a pioneering private sector company, we advocate for the implementation of effective, sustainable public health, clinical, and community initiatives. Our efforts are geared towards improving early detection of Alzheimer's, reducing stigma, and advancing health equity. We strive to empower stakeholders to create pathways from increased detection to evidence-based care by:"
        details1=" the process of detecting cognitive impairments related to Alzheimer's and developing a comprehensive care pathway. Our approach is outlined in four straightforward steps, with each step backed by successful strategies used in various healthcare environments."
        details2=" the need to incorporate Alzheimer's early detection into primary and other healthcare settings, as well as community environments. By shortening the time between the detection of cognitive impairment and the implementation of appropriate care, people with Alzheimer's can enjoy a higher quality of life."
        details3=" evidence-based practices to elevate the rate of early Alzheimer's detection on a national scale. Our company offers support in capacity building, decision-making, and strategic planning, aiding in the improvement of Alzheimer's detection, services, and support systems."
        details4=" the development of collaborative solutions to improve Alzheimer's detection and care. We facilitate the creation of partnerships between stakeholders, including healthcare providers, community organizations, and public health agencies."
      />
      <QuesMark />
      <WeFacilitate
        Headings="WE FACILITATE:"
        weFacilitate={imageLinks.weFacilitate}
        Description="We envision a future where Alzheimer's disease is detected at its earliest stages, preventing the escalation into severe crises. In this future, early identification of Alzheimer's is key to enhancing the health and wellbeing of older adults, as well as bringing assurance and support to their families and friends.

        As a proactive private company, we are committed to investigating and understanding the challenges and barriers that hinder early detection of Alzheimer's. We actively seek out and evaluate effective strategies that can be employed to improve early detection methodologies.
        
        Our goal is to drive the adoption of these solutions through collaborative efforts with stakeholders nationwide. We believe in the power of partnership and innovation to transform the approach to Alzheimer's care. By uniting with healthcare professionals, organizations, and communities, we aim to spearhead advancements in early detection and care, making a meaningful difference in the lives of those affected by Alzheimer's."
      />
      <GetInTouch
        Headings="GET IN TOUCH"
        Description="Our company is dedicated to offering assistance to organizations aiming to deepen their understanding of early Alzheimer's detection or those looking to initiate or enhance their early detection programs. We are here to share our expertise and insights in this vital area. If your organization is seeking guidance or support in Alzheimer's early detection strategies, don't hesitate to reach out to our team via email:"
      />
      <Footer />
    </>
  );
}

export default App;
